<template>
    <div class="chat-control-panel">
        <ChatUsersNavbar class="chat-control-panel__navbar" horizontal :chat-data="chatData" @change-card="$emit('change-card')"/>
        <div class="chat-control-panel__acceptbar">
            <transition name="agent-enter" mode="out-in">
                <ChatIncoming v-if="incomingChat" key="1"/>
                <ChatIncomingEmpty v-else key="2"/>
            </transition>
        </div>
        <ChatClientPanel v-if="chatData" class="chat-control-panel__clientbar" :chat-data="chatData" @edit="$emit('edit')"/>
    </div>
</template>
<script>
import ChatIncoming from "./ChatIncoming";
import ChatIncomingEmpty from "./ChatIncomingEmpty";
import {mapState} from 'vuex';
import ChatUsersNavbar from "./ChatUsersNavbar";
import {ChatRoom} from "../../../../services/chat/ChatModel";
const ChatClientPanel = () => import("./ChatClientPanel");

export default {
    components: {
        ChatUsersNavbar,
        ChatClientPanel,
        ChatIncomingEmpty,
        ChatIncoming,
    },
    props: {
        chatData: ChatRoom,
    },
    computed: {
        ...mapState('chat', ['incomingChat'])
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';

.hello-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: #212121;
    font-family: $font-secondary;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
    svg {
        width: 18px;
        height: 18px;
        color: #949494;
        margin-right: 4px;
        vertical-align: sub;
    }

    &--size-medium {
        font-size: 16px;
        max-width: 220px;
        svg {
            margin-bottom: -2px;
            margin-right: 12px;
            width: 24px;
            height: 24px;
        }
    }
    &--theme-danger {
        color: #FF4921;
    }
}
.chat-hello {
    $self: &;
    display: flex;
    align-items: center;
    &__image {
        margin-right: 14px;
        width: 58px;
        height: 58px;
        background-color: var(--color-border-lighten);
        border-radius: 100%;
        img {
            width: 52px;
            height: 52px;
            margin-right: -2px;
            margin-top: -2px;
            margin-left: auto;
            display: block;
        }
    }
    &__name {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: var(--color-primary-dark);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &--size-medium {
        #{$self}__wrap {
            width: calc(100% - 68px);
        }
        #{$self}__image {
            width: 44px;
            height: 44px;
            margin-right: 24px;
            img {
                width: 52px;
                height: 52px;
                margin: -7px -4px -1px;
            }
        }
        #{$self}__name {
            margin-bottom: 8px;
            font-size: 18px;
            line-height: 22px;
        }
    }
    &--empty {
        #{$self}__image {
            mix-blend-mode: luminosity;
        }
        #{$self}__name {
            margin-top: 4px;
            white-space: unset;
        }
    }
    &--theme-success {
        #{$self}__image {
            background-color: #DCF7BA;
        }
    }
}
.chat-control-panel {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    &__navbar {
        width: 57.92%;
        margin-right: 20px;
    }
    &__acceptbar {
        width: calc(42.08% - 20px);
        border-radius: 12px;
        overflow: hidden;
    }
    &__clientbar {
        width: 100%;
        margin-top: 20px;
    }
}
</style>
