<template>
    <transition name="footer-button">
        <div v-show="visible" class="chat-window__footer-button" @click="$emit('scroll-down', false)">
            <div v-show="chatData.unreadMessages" class="chat-window__footer-counter message-counter">{{ chatData.unreadMessages }}</div>
            <AppIcon icon="chevron-down"/>
        </div>
    </transition>
</template>
<script>
import AppIcon from "../../AppIcon";
import {ChatRoom} from "../../../../services/chat/ChatModel";
export default {
    name: 'ChatWindowScrollButton',
    components: {AppIcon},
    props: {
        chatData: ChatRoom,
        visible: Boolean
    },
}
</script>
