<template>
<div class="chat-incoming">
    <div class="chat-incoming__item chat-hello chat-hello--size-medium chat-hello--theme-success">
        <div class="chat-hello__image">
            <img src="@/assets/img/chat/hello-chat-001.gif" alt="">
        </div>
        <div class="chat-hello__wrap">
            <div class="chat-hello__name">{{ incomingChat.userName }}</div>
            <div class="hello-name-wrap block-wrap block-wrap--offset-medium">
                <div class="hello-name">
                    <AppIcon icon="phone-calling" size="18"/>{{ incomingChat.phone || '-' }}
                </div>
                <div class="hello-name">
                    <AppIcon icon="globe" size="18"/>{{ langName || '-' }}
                </div>
                <div class="hello-name">
                    <AppIcon icon="source-bubble" size="18"/>{{ incomingChat.source || '-' }}
                </div>
            </div>
        </div>
    </div>

    <AppButton class="chat-incoming__button chat-button chat-button--animated" theme="success" @click="acceptChat">
        {{ $t("components.common.acceptChat") }}
    </AppButton>
</div>
</template>
<script>
import {mapState} from 'vuex';
import AppIcon from "../../AppIcon";
import AppButton from "../../AppButton";
import incomingChatService from "../../../../services/chat/IncomingChatService";
export default {
    name: 'ChatIncoming',
    components: {AppButton, AppIcon},
    computed: {
        ...mapState('chat', ['incomingChat']),
        langName() {
            switch(this.incomingChat.lang) {
                case 'ru': {
                    return 'Русский';
                }
                case 'uz': {
                    return 'Узбекский';
                }
                case 'qq': {
                    return 'Каракалпакский';
                }
            }
        }
    },
    methods: {
        acceptChat() {
            incomingChatService.acceptChat();
        },
    }
}
</script>
<style lang="scss">
.chat-incoming {
    display: flex;
    align-items: center;
    padding: 20px 34px 22px 30px;
    background: #ECFFD3;
    &__item {
        margin-right: 24px;
        width: 100px;
        flex-grow: 1;
    }
    &__button {
        flex-shrink: 0;
    }
}
</style>
