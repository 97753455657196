<template>
    <div class="chat-view" ref="view">
        <ChatLayout :processing-cards="processingCards" @process-cards="$emit('process-cards', $event)" @process-cards-delay="$emit('process-cards-delay', $event)"/>
    </div>
</template>
<script>
import ChatController, {ERROR} from "../../../services/ChatController";
import PushNotificationsService from "../../../services/PushNotificationsService";
import incomingChatService from "../../../services/chat/IncomingChatService";
import activeChatService from "../../../services/chat/ActiveChatService";
import ChatLayout from "./ChatLayout";
import {mapState} from "vuex";
import ChatMessagesService from "../../../services/chat/ChatMessagesService";
import {get} from "../../../helpers/api";
import {transpileScript} from "../../../helpers/chats";
import ABSCustomer from "../../../services/customer/ABSCustomer";
import identificationChatService from "../../../services/chat/IdentificationChatService";

export default {
    name: 'ChatView',
    props: {
        processingCards: {
            type: Object,
        }
    },
    components: {
        ChatLayout,
    },
    data() {
        return {
            pushNotifications: null
        }
    },
    computed: {
        ...mapState('chat', ['activeChatId', 'incomingChat', 'chatsData']),
    },
    created() {
        this.pushNotifications = new PushNotificationsService('Новый чат', 'Вам поступил входящий чат', this.acceptChat);
        ChatMessagesService.listen();
        incomingChatService.listen();
        ChatController.on(ERROR, this.onError);
        window.addEventListener('beforeunload', this.beforeUnload);
        identificationChatService.connectNotificationsSocket();
        this.loadScripts();
    },
    beforeDestroy() {
        ChatMessagesService.destroy();
        ChatController.off(ERROR, this.onError);
        incomingChatService.destroy();
        activeChatService.destroy();
        identificationChatService.disconnectNotificationsSocket();
        this.clearAcceptChatOnKeyboard();
        this.beforeUnload();
        window.removeEventListener('beforeunload', this.beforeUnload);
    },
    watch: {
        incomingChat: {
            handler(value) {
                if(value) {
                    document.addEventListener('keydown', this.acceptChatOnKeyboard);
                    this.$store.commit("changeStatus", 0);
                    this.pushNotifications.createNotification();
                } else {
                    this.clearAcceptChatOnKeyboard();
                }
            },
            immediate: true
        },
        activeChatId(value) {
            if(!value) {
                ABSCustomer.clearData();
                this.$store.commit('clearSearchError');
                this.$store.commit('setSearchEnd');
            }
        }
    },
    methods: {
        loadScripts() {
            get("/scripts/non-voice").then(res => {
                let operator = this.$store.state.operator.operator.name;
                this.$store.commit('setChatScripts', res.data.data.map(script => {
                    return {
                        id: script.id,
                        isChat: script.isChat,
                        name: script.name,
                        children: script.children.map(child => {
                            let text_ru = child.text_ru;
                            try {
                                text_ru = transpileScript(text_ru, operator);
                            } catch(e) {
                                console.error('Ошибка в переводе text_ru. Имя оператора ' + operator, e);
                            }
                            let text_uz = child.text_uz;
                            try {
                                text_uz = transpileScript(text_uz, operator);
                            } catch(e) {
                                console.error('Ошибка в переводе text_uz. Имя оператора ' + operator, e);
                            }

                            return {
                                id: child.id,
                                text_ru,
                                text_uz,
                                text_qq: text_uz
                            }
                        })
                    }}));
            });
        },

        beforeUnload() {
            Object.values(this.chatsData).forEach(chatRoom => {
                ChatController.leaveChat(chatRoom.data)
                    .then(() => {
                        activeChatService.saveChat(chatRoom);
                    })
            });
        },

        acceptChatOnKeyboard(e) {
            if(e.code === 'Enter') {
                if(!this.$store.state.isChat && this.incomingCall) {
                    return;
                }
                if(!this.$store.state.isChat) {
                    this.$store.commit('changeChatLayout', true);
                }
                incomingChatService.acceptChat();
                this.clearAcceptChatOnKeyboard();
            }
        },
        acceptChat() {
            incomingChatService.acceptChat();
        },
        clearAcceptChatOnKeyboard() {
            document.removeEventListener('keydown', this.acceptChatOnKeyboard);
        },

        onError(e) {
            if(e.message === 'chatStateCheck') {
                this.$store.commit('emitConnectionError');
            } else {
                this.$store.commit('notifications/error', e.message);
            }
        },
    }
}
</script>
<style lang="scss">
@import '../../../assets/scss/variables';
.chat-button {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    font-family: $font-primary;
    padding-left: 42px;
    padding-right: 42px;
    &--animated {
        position: relative;
        animation: pulseSize 2s infinite ease-out both;
        &:after {
            content: "";
            z-index: -2;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: calc(-50% + -14px);
            margin-top: -38px;
            width: calc(100% + 28px);
            height: 76px;
            border-radius: inherit;
            background-color: inherit;
            opacity: 0.6;
            animation: pulse 1.5s infinite ease-out both;
        }
    }
}
</style>
