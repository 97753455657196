<template>
    <div class="chat-users-navbar" :class="horizontal ? 'chat-users-navbar--horizontal' : 'chat-users-navbar--vertical'">
        <ChatUserPan v-for="item in chatsList" :key="item.chatRoom || item"
                     class="chat-users-navbar__item"
                     :class="{'chat-users-navbar__item--active': item.chatRoom && item.chatRoom === activeChat, 'clickable-item': item.chatRoom}"
                     :horizontal="horizontal"
                     :chat-data="item.chatRoom ? item : null"
                     :active="item.chatRoom === activeChat" @click="inputChat(item.chatRoom)"/>
        <transition v-if="chatData && queueVisible" name="queue-animation" mode="out-in">
            <div :key="queueCount" class="chat-users-navbar__queue-count">{{ queueCountName }}</div>
        </transition>
    </div>
</template>
<script>
import {mapState} from "vuex";
import socket from "../../../../services/StatistiscsSocket";
import activeChatService from "../../../../services/chat/ActiveChatService";
import {getChatNavbarList} from "../../../../helpers/chats";
import ChatUserPan from "../ChatUserPan";
import {ChatRoom} from "../../../../services/chat/ChatModel";
export default {
    name: 'ChatUsersNavbar',
    components: {ChatUserPan},
    props: {
        chatData: ChatRoom,
        horizontal: Boolean
    },
    data() {
        return {
            queueCount: 0
        }
    },
    computed: {
        ...mapState('chat', ['chatsData', 'activeChatId']),
        activeChat() {
            return this.chatData ? this.chatData.chatRoom : null;
        },
        chatsList() {
            return getChatNavbarList(this.chatsData);
        },
        queueVisible() {
            return this.horizontal ? !!this.queueCount : true;
        },
        queueCountName() {
            if(this.horizontal) return `ещё же\n+${this.queueCount} ${this.queueCount === 1 ? 'ждет' : 'ждут' }`;
            switch(this.queueCount) {
                case 0: return `все в порядке, никто не ждет`;
                case 1: return `о нет, там один ждет`;
                default: return `о нет, там +${ this.queueCount } ждут`;
            }
        },
    },
    mounted() {
        socket.subscribe(this.updateQueue);
        if(socket.lastChatMessage) {
            this.queueCount = socket.lastChatMessage.ChatQueueStatistics.contactsQueued;
        }
    },
    beforeDestroy() {
        socket.unsubscribe(this.updateQueue);
    },
    methods: {
        inputChat(chatRoom) {
            if(chatRoom) {
                if(chatRoom === this.activeChatId) {
                    this.$emit('change-card');
                }
                activeChatService.setActiveChat(chatRoom);
            }
        },
        updateQueue(message) {
            if(message.ChatQueueStatistics) {
                this.queueCount = message.ChatQueueStatistics.contactsQueued;
            }
        },
    }
}
</script>
<style lang="scss">
.chat-users-navbar {
    $self: &;
    background-color: #fff;
    border-radius: 12px;
    &__queue-count {
        background: #2552C6;
        padding: 10px 12px;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        color: #FFFFFF;
    }
    &--vertical {
        display: block;
        position: relative;
        #{$self}__item {
            transition: background-color .25s;
            padding-top: 16px;
            padding-bottom: 16px;
            &:first-child {
                border-radius: 12px 12px 0 0;
            }
            &:nth-child(5) {
                border-radius: 0 0 12px 12px;
            }
            &--active {
                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -41px;
                    height: 82px;
                    width: 2px;
                    background-color: #3A79F3;
                    border-radius: 12px;
                }
            }
        }
        #{$self}__queue-count {
            position: absolute;
            top: 100%;
            left: 0;
            border-radius: 0px 12px 12px 12px;
            width: 100%;
        }
        .queue-animation {
            &-leave-active, &-enter-active {
                transition: transform .35s, opacity .35s;
            }
            &-leave-to, &-enter {
                transform: translateX(-48px);
                opacity: 0;
            }
        }
    }
    &--horizontal {
        padding: 16px 31px 16px 34px;
        border-radius: 12px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        #{$self}__item {
            width: 15%;
            flex-grow: 1;
            margin-right: 16px;
            position: relative;
            &--active:after {
                content: '';
                position: absolute;
                bottom: -17px;
                left: 50%;
                margin-left: -29px;
                width: 58px;
                height: 2px;
                background-color: #3A79F3;
                border-radius: 12px;
            }
            &:nth-child(5) {
                margin-right: 0;
            }
        }
        #{$self}__queue-count {
            position: absolute;
            top: -4px;
            right: -8px;
            border-radius: 12px 12px 12px 4px;
            white-space: pre;
        }
        .queue-animation {
            &-enter-active, &-leave-active {
                animation: controlTooltip var(--animation-elastic-2) 1s;
                overflow: hidden;
                transform-origin: 0 100%;
            }
            &-leave-active {
                animation: controlTooltip var(--animation-elastic-1) 1s reverse;
            }
        }
    }
}
@keyframes controlTooltip {
    0% {
        opacity: 0;
        transform: rotate(-135deg);
    }
    15% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}
</style>
