<template>
    <div class="chat-window__menu-wrap" v-clickaway="close">
        <button class="chat-window__input-control" type="button" :class="{'chat-window__input-control--active': opened}" @click="opened = !opened" v-tooltip="$t('components.common.templateMessages')">
            <AppIcon icon="annotation"/>
        </button>
        <transition name="action-menu">
            <div v-if="opened" class="chat-window__menu">
                <div class="chat-tips-menu">
                    <div class="chat-tips-menu__heading">{{ $t("components.common.responseTemplates") }}</div>
                    <button type="button" class="chat-tips-menu__close" @click="close">
                        <AppIcon icon="close"/>
                    </button>
                    <div v-if="false" class="chat-tips-menu__search tip-search">
                        <AppIcon class="tip-search__icon" icon="search"/>
                        <AppInput class="tip-search__input" :placeholder="$t('components.common.searchByAnswers')"/>
                    </div>

                    <transition name="slide" mode="out-in">
                        <div v-if="selectedCategory" key="1" class="chat-tips-menu__slide" :class="{'chat-tips-menu__slide--scrolled': itemsScroll}">
                            <div class="chat-tips-menu__controls">
                                <div class="chat-tips-menu__back" @click="selectCategory(null)">
                                    <AppIcon icon="chevron-left"/>
                                    {{ $t("components.common.back") }}
                                </div>
                                <div class="chat-tips-menu__body-name">{{ selectedCategory.name }}</div>
                            </div>
                            <div class="chat-tips-menu__body-data tip-menu-data-wrap" ref="slide-item">
                                <div v-for="item in selectedCategory.children" class="chat-tips-menu__item tip-menu-data" @click="select(item)">{{ getReplacedName(item) }}</div>
                            </div>
                        </div>
                        <div v-else key="2" class="chat-tips-menu__slide" :class="{'chat-tips-menu__slide--scrolled': itemsScroll}">
                            <div class="chat-tips-menu__body-items" ref="slide-item">
                                <div v-for="item in dataCategories" :key="item.id" class="chat-tips-menu__item tip-menu-item" @click="selectCategory(item)">
                                    <div class="tip-menu-item__name">{{ item.name }}</div>
                                    <AppIcon class="tip-menu-item__indicator" icon="chevron-right"/>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import AppIcon from "../AppIcon";
import AppInput from "../AppInput";
import ListUtils from "../../../mixins/ListUtils";
import clickaway from "../../../directives/clickaway";
export default {
    name: 'ChatTipsMenu',
    components: {AppInput, AppIcon},
    props: {
        chatData: Object
    },
    directives: {
        clickaway
    },
    mixins: [ListUtils],
    data() {
        return {
            selectedCategory: null,
            itemsScroll: false,
            opened: false
        }
    },
    computed: {
        lang() {
            return this.chatData.lang;
        },
        dataCategories() {
            return this.$store.state.chatScripts
        },
        localizedName() {
            return 'text_' + this.lang;
        },
        operatorName() {
            return this.$store.state.operator.operator.name;
        }
    },
    methods: {
        open() {
            this.opened = true;
        },
        close() {
            this.opened = false;
            this.$emit('close');
        },
        selectCategory(item) {
            this.selectedCategory = item;
            setTimeout(() => {
                const slideItem = this.$refs["slide-item"];
                if(slideItem) {
                    this.itemsScroll = slideItem.scrollHeight > slideItem.offsetHeight;
                }
            }, 500)
        },
        select(item) {
            this.$emit('select', this.getReplacedName(item));
            this.selectCategory(null);
            this.close();
        },
        getReplacedName(item) {
            return this.replaceUsername(item[this.localizedName], this.chatData.data.userName, this.operatorName);
        }
    }
}
</script>
<style lang="scss">
@import '../../../assets/scss/variables';
.slide {
    &-enter-active, &-leave-active {
        transition: transform .25s;
    }
    &-enter {
        transform: translateX(100%);
    }
    &-leave-to {
        transform: translateX(-100%);
    }
}
.tip-search {
    position: relative;
    background: #F4F4F4;
    border-radius: 12px;
    &__input {
        display: block;
        width: 100%;
        height: 48px;
        background-color: transparent;
        padding-left: 54px;
        border: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        font-family: $font-secondary;
        letter-spacing: 0.005em;
    }
    &__search {
        position: absolute;
        top: 15px;
        left: 20px;
        width: 18px;
        height: 18px;
        color: #717171;
        display: block;
    }
}
.tip-menu-data-wrap {
    counter-reset: tip;
}
.tip-menu-data {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    border: 1px solid #E1E1E1;
    border-radius: 12px;
    padding: 24px 24px 24px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--color-gray);
    &:before {
        counter-increment: tip;
        content: counter(tip) '.';
        width: 47px;
        text-align: right;
        padding-right: 14px;
        flex-shrink: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #3C3C3C;
    }
    &:hover {
        background: #F4F4F4;
    }
}
.tip-menu-item {
    $self: &;
    cursor: pointer;
    user-select: none;
    padding: 15px 25px 15px 23px;
    min-height: 72px;
    border: 1px solid #E1E1E1;
    display: flex;
    align-items: center;
    border-radius: 12px;
    &__name {
        margin-right: auto;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        font-family: $font-secondary;
        letter-spacing: 0.005em;
        color: #717171;
    }
    &__indicator {
        color: #717171;
        width: 16px;
        height: 16px;
    }
    &:hover {
        #{$self}__indicator,
        #{$self}__name {
            color: var(--color-dark);
        }
    }
}
.chat-tips-menu {
    font-family: $font-secondary;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.13);
    border-radius: 12px;
    height: calc(100vh - 128px - var(--chat-layout-start-offset) - var(--home-tabs-height));
    width: 478px;
    padding: 24px 18px 0;
    position: relative;
    overflow: hidden;
    &__close {
        position: absolute;
        right: 13px;
        top: 19px;
        padding: 5px;
        background-color: transparent;
        border: 0;
        svg {
            width: 24px;
            height: 24px;
            display: block;
        }
    }
    &__heading {
        margin-bottom: 24px;
        padding: 0 6px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        font-family: $font-primary;
    }
    &__search {
        margin-bottom: 24px;
    }
    &__slide {
        margin-left: -18px;
        margin-right: -24px;
        padding-left: 18px;
        padding-right: 24px;
        padding-bottom: 24px;
        overflow: hidden;
        height: calc(100% - 48px);
        &--scrolled {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 24px;
                background: #FFFFFF;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
                border-radius: 12px 12px 0px 0px;
            }
        }
    }
    &__body-data {
        overflow-y: auto;
        height: calc(100% - 38px);
        padding-top: 18px;
    }
    &__body-items {
        overflow-y: auto;
        height: 100%;
    }
    &__item {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__controls {
        display: flex;
        justify-content: space-between;
        padding-bottom: 17px;
        border-bottom: 1px solid #E1E1E1;
    }
    &__back {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: var(--color-primary);
        svg {
            margin-right: 8px;
            width: 16px;
            height: 16px;
        }
    }
    &__body-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.005em;
        color: #ADADAD;
    }
}
.tips-slide {
    &-enter-active, &-leave-active {
        transition: transform .25s;
    }
    &-enter {
        transform: translateX(-100%);
    }
    &-leave-to {
        transform: translateX(-100%);
    }
}
</style>
