<template>
    <div class="chats-no-client">
        <WaitingImage is-chat :calling="!!incomingChat"/>
        <WaitingStatistics class="chats-no-client__waiting-statistics" is-chat/>
    </div>
</template>
<script>
import WaitingImage from "../WaitingImage";
import WaitingStatistics from "../WaitingStatistics";
import incomingChatService from "../../../services/chat/IncomingChatService";
export default {
    name: 'ChatNoClients',
    components: {WaitingStatistics, WaitingImage},
    computed: {
        incomingChat() {
            return incomingChatService.incomingChat;
        }
    }
}
</script>
<style lang="scss">
.chats-no-client {
    display: flex;
    flex-direction: column;
    padding: 32px 64px;
    flex-grow: 1;
    &__waiting-statistics {
        margin-bottom: 0;
    }
}
</style>
