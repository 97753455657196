<template>
    <div class="chat-window__panel">
        <AppIcon icon="info-circle"/>
        {{ $t("components.common.theIdentificationRequestHasBeenSent") }}
        <span v-if="identificationTimer.timerData.timer">{{ $t("components.common.youCanResendVia") }} {{ identificationTimer.getMinutesSecondsAndFormat() }}</span>
        <div v-if="!identificationTimer.timerData.timer" class="chat-window__panel-tip link-item" @click="sendUserIdentification">
            {{ $t("components.common.resend") }}
        </div>
    </div>
</template>
<script>
import AppIcon from "../../AppIcon";
import TimerService from "../../../../services/TimerService";
import {ChatRoom} from "../../../../services/chat/ChatModel";
import identificationChatService from "../../../../services/chat/IdentificationChatService";
export default {
    components: {AppIcon},
    props: {
        chatData: {
            type: ChatRoom,
            required: true
        }
    },
    data() {
        return {
            identificationTimer: null,
        }
    },
    watch: {
        'chatData.requestResendAt': {
            handler: 'createTimer',
            immediate: true,
            deep: true
        }
    },
    beforeDestroy() {
        this.identificationTimer.clearTimer();
    },
    methods: {
        createTimer() {
            const delta = Math.max(Math.round((this.chatData.requestResendAt - Date.now())/1000), 0);
            this.identificationTimer = new TimerService(delta);
            if(delta) {
                this.identificationTimer.startTimer();
            }
        },
        sendUserIdentification() {
            identificationChatService.sendIdentification({}, this.chatData);
        },
    }
}
</script>
