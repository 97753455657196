<template>
    <div class="chat-window__menu-wrap" v-clickaway="close">
        <AppButton class="chat-window__control" :theme="opened ? 'primary' : 'gray'" size="medium-extra" round
                   @click="opened = !opened" v-tooltip="$t('components.common.chatManagement')">
            <AppIcon icon="menu"/>
        </AppButton>
        <transition name="dialer">
            <div v-if="opened" class="chat-window__menu chat-window__menu--down">
                <div class="search-menu">
                    <div class="search-menu__item" @click="terminateChat">{{ $t("components.common.endChat") }}</div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import clickaway from "../../../../directives/clickaway";
import AppIcon from "../../AppIcon";
import AppButton from "../../AppButton";
import {ChatRoom} from "../../../../services/chat/ChatModel";
import activeChatService from "../../../../services/chat/ActiveChatService";

export default {
    name: 'ChatWindowSettings',
    components: {AppButton, AppIcon},
    directives: {
        clickaway
    },
    props: {
        chatData: ChatRoom
    },
    data() {
        return {
            opened: false
        }
    },
    methods: {
        close() {
            this.opened = false;
            this.$emit('close');
        },
        open() {
            this.opened = true;
            this.$emit('open');
        },
        terminateChat() {
            this.close();
            activeChatService.terminateChat(this.chatData);
        }
    }
}
</script>
