import ChatController, {ACCEPTED_CHAT} from "../ChatController";
import app from "../../main";
import {ChatRoom} from "./ChatModel";
import {post} from "../../helpers/api";

// Синглтон для управления активным чатом
class ActiveChatService {
    constructor() {
        this.onAcceptedChat = this.onAcceptedChat.bind(this);
        ChatController.on(ACCEPTED_CHAT, this.onAcceptedChat);
    }
    destroy() {
        ChatController.off(ACCEPTED_CHAT, this.onAcceptedChat);
    }

    onAcceptedChat(chatData) {
        const chatRoomId = chatData.chatRoom;
        app.$store.commit("chat/addChatData", new ChatRoom(chatData));
        this.setActiveChat(chatRoomId);
    }
    setActiveChat(chatRoomId) {
        app.$store.commit("chat/setActiveChat", chatRoomId);
    }
    /**
     * Связывает обращения от ПОСЛЕДНЕГО выбранного клиента с завершаемым чатом
     * @param {ChatRoom} chatData
     */
    saveChat(chatData) {
        if(!chatData.selectedCustomer) return;

        const data = {
            "dialogId": chatData.data.dialogID,
            "customerId": chatData.selectedCustomer,
            "sourceId": chatData.sourceId,
        };

        if (chatData.data.modileId > 0) {
            data.mobileId = chatData.data.modileId
        }

        const problemsActions = chatData.problemsActions[chatData.selectedCustomer];

        if(problemsActions.length) {
            data.applications = problemsActions;

            problemsActions.forEach(problem => {
                app.$store.commit("problems/removeProblem", problem);
            });

            post("/end-call/", {
                application_id: problemsActions[problemsActions.length - 1],
                identification_request_id: chatData.requestId || 0
            });
        }
        post("/chat-history", data);
    }

    terminateChat(chatData) {
        if(!chatData.selectedCustomer) {
            return app.$store.commit("notifications/error", "Пожалуйста, перед закрытием чата выберите клиента или создайте новую карточку клиента");
        }

        if (chatData.problemsActions[chatData.selectedCustomer] && chatData.problemsActions[chatData.selectedCustomer].length === 0) {
            app.$store.commit("notifications/error", "Ай-яй-яй, почему не создали обращение или не изменили те, что есть?");
            return;
        }

        ChatController.leaveChat(chatData.data)
            .then(() => this.saveChat(chatData));

        app.$store.commit("chat/removeChatData", {
            chatRoom: chatData.chatRoom,
        });

        let activeChat = null;

        const chatList = Object.keys(app.$store.state.chat.chatsData);
        if(chatList.length) {
            activeChat = chatList[0];
        }
        app.$store.commit("chat/setActiveChat", activeChat);

        if (chatList.length === 0) {
            app.$store.commit("setOperatorPickedUpPhone", false);
        }
    }
}
export default new ActiveChatService();
