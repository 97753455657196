<template>
    <div class="chat-layout" :class="'chat-layout--state-' + animationState" :style="layoutStyle">
        <div class="chat-layout__fixed">
            <transition name="chat-layout-tabs">
                <HomeTabs v-show="selectedUser.id || incomingCall" class="chat-layout__home-tabs" :user-data="selectedUser" :selected-view="selectedView" @input="changeCard"/>
            </transition>
            <ChatControlPanel v-if="!activeChat || selectedView !== 1" class="chat-layout__panel-wrap" :chat-data="activeChatData" @edit="changeCard(1)" @change-card="changeCard(1)"/>
        </div>

        <ChatNoClients v-if="!activeChat" class="chat-layout__no-clients"/>
        <keep-alive v-else include="ChatForm" :max="10">
            <ChatForm v-if="selectedView === 1" :key="activeChat" :chat-data="activeChatData"/>
            <HistoryProblems v-else-if="selectedView === 2" key="3" class="chat-layout__fullsize chat-layout__fullsize--has-offset" is-chat :call-data="activeChatData"/>
            <MybankProduct v-else-if="selectedView === 4" key="4" class="chat-layout__fullsize" :processing-cards="processingCards"
                           :user-data="selectedUser" :identification-data="identificationData" @request-identification="sendIdentification"
                           @process-cards="$emit('process-cards', $event)" @process-cards-delay="$emit('process-cards-delay', $event)"/>
            <MonitoringProducts v-else-if="selectedView === 5" key="5" class="chat-layout__fullsize" is-chat :user-data="selectedUser" :common-data="commonData"/>
            <OutgoingHistory v-else-if="selectedView === 6" :customer-id="activeChatData.selectedCustomer.toString()" />
        </keep-alive>
    </div>
</template>
<script>
import {mapState} from "vuex";
import HomeTabs from "../home/HomeTabs";
import ChatControlPanel from "./controls/ChatControlPanel";
import ChatNoClients from "./ChatNoClients";
import ChatUsersNavbar from "./controls/ChatUsersNavbar";
import ChatWindow from "./ChatWindow";
import AboutClient from "../../aboutClient";
import OutgoingHistory from "../../outgoing-history.vue";
import {getUserDataFromCallData} from "../../../helpers/chats";
import identificationChatService from "../../../services/chat/IdentificationChatService";
import ChatForm from "./ChatForm";
const HistoryProblems = () => import("../../historyProblems");
const MybankProduct = () => import("../../MybankProduct");
const MonitoringProducts = () => import("../../MonitoringProducts");

export default {
    name: 'ChatLayout',
    components: {
        ChatForm,
        MonitoringProducts,
        MybankProduct,
        HistoryProblems, AboutClient, ChatWindow, ChatUsersNavbar, ChatNoClients, ChatControlPanel, HomeTabs, OutgoingHistory},
    props: {
        processingCards: {
            type: Object
        },
    },
    data() {
        return {
            selectedView: 1,
            activeChat: null,
            animationState: '',
            changeDelay: null,
            preservedChatsData: {}
        }
    },
    computed: {
        ...mapState('chat', ['activeChatId', 'chatsData', 'incomingChat']),
        ...mapState('callData', ['incomingCall']),
        selectedUser() {
            return getUserDataFromCallData(this.activeChatData);
        },
        alternateView() {
            return this.selectedView === 1 && !!this.activeChat;
        },

        identificationData() {
            return {
                requestId: this.activeChatData.requestId,
                requestResolved: this.activeChatData.requestResolved
            }
        },
        commonData() {
            let phone = ""

            if (this.activeChatData.selectedPhone) {
                phone = this.activeChatData.selectedPhone.match(/\d+/g).join("")
            } else if (this.activeChatData.data.phone) {
                phone = this.activeChatData.data.phone.match(/\d+/g).join("")
            }

            return {
                lang: this.activeChatData.lang,
                phone: phone,
            }
        },
        hasSubscribe() {
            return !!this.$store.state.teamMessages.messages.length;
        },
        layoutStyle() {
            return {
                '--subscribe-height': this.hasSubscribe ? '54px' : '0px',
                '--client-info-height': !this.alternateView && this.activeChat ? '94px' : '0px',
                '--panel-wrap-height': !this.alternateView && this.activeChat ? '20px' : '0px',
                '--connection-error-height': this.$store.state.connectionError ? '54px' : '0px',
                '--home-tabs-height': this.selectedUser.id || this.incomingCall ? '60px' : '0px'
            }
        },
        activeChatData() {
            return this.preservedChatsData[this.activeChat];
        }
    },
    mounted() {
        this.preservedChatsData = {...this.chatsData};
    },
    watch: {
        activeChatId: {
            handler(value) {
                this.changeCard(1, () => {
                    this.activeChat = value;
                    this.preservedChatsData = {...this.chatsData};
                });
            },
            immediate: true
        },
    },
    methods: {
        changeCard(card, cb) {
            if(this.changeDelay) {
                clearTimeout(this.changeDelay);
                this.animationState = 'entering';
                this.activeChat = this.activeChatId;
                this.changeDelay = null;
                this.selectedView = card;
                return;
            }
            this.animationState = 'leaving';
            this.changeDelay = setTimeout(() => {
                if(cb) cb();
                this.animationState = 'entering';
                this.selectedView = card;
                this.changeDelay = setTimeout(() => {
                    this.animationState = '';
                    this.changeDelay = null;
                }, 495);
            }, 485);
        },
        sendIdentification(options) {
            this.changeCard(1);
            identificationChatService.sendIdentification(options, this.activeChatData);
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/animation';
.chat-layout {
    --home-tabs-height: 60px;
    --user-panel-height: 196px;
    --user-panel-offset: 32px;
    --chat-layout-start-offset: calc(var(--subscribe-height) + var(--header-height) + var(--connection-error-height));
    height: calc(100vh - var(--chat-layout-start-offset));
    transition: height .175s;
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    &__chat-content {
        display: flex;
        align-items: flex-start;
        padding: 16px 64px;
        height: 100px;
        flex-grow: 1;
        overflow: auto;
        overflow-x: hidden;
    }
    &__chat-wrap {
        width: 57%;
        position: sticky;
        top: 0;
        display: flex;
        align-items: flex-start;
    }
    &__chat-window {
        position: sticky;
        top: 0;
        width: calc(100% - 146px);
        margin-left: 20px;
        flex-grow: 1;
        transition: height .35s;
        height: calc(100vh - var(--chat-layout-start-offset) - var(--home-tabs-height) - 32px);
    }
    &__chat-form {
        width: calc(43% - 20px);
        flex-grow: 1;
        margin-left: 20px;
        position: sticky;
        top: 0px;
    }
    &__chat-navbar {
        position: sticky;
        top: 0;
        width: 126px;
        margin-bottom: 44px;
    }
    &__client-card {
        margin-left: 20px;
        flex-grow: 1;
    }
    &__no-clients {
        height: calc(100% - 132px);
    }
    &__fullsize {
        padding-bottom: 32px;
        &--has-offset {
            padding: 0 64px 32px;
        }
        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }
    &__panel-wrap {
        padding: 8px 64px 0;
        background-color: var(--color-white-bg);
        margin-bottom: 32px;
    }
    &__panel {
        margin-bottom: 4px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__panel-fixed {
        z-index: 3;
        position: fixed;
        top: calc(var(--header-height) + var(--subscribe-height) + var(--connection-error-height) + var(--home-tabs-height) + 8px);
        left: var(--sidebar-width);
        right: 0px;
    }
    &__fixed {
        z-index: 3;
        position: sticky;
        top: 0;
        left: 0;
    }
    &__home-tabs {
        position: relative;
        z-index: 1;
        overflow: hidden;
    }
}
.chat-layout-tabs {
    &-leave-active,
    &-enter-active {
        transition: height .35s;
    }
    &-enter,
    &-leave-to {
        height: 0px;
    }
    &-enter-to,
    &-leave {
        height: 60px;
    }
}
.chat-layout--state {
    $self: '.chat-layout';
    &-entering {
        #{$self}__panel-wrap, #{$self}__no-clients,
        #{$self}__chat-wrap, #{$self}__chat-form, #{$self}__fullsize {
            animation: translateY .5s 1 both;
        }
        #{$self}__panel-wrap {
            animation-timing-function: var(--animation-elastic-2);
        }
        #{$self}__chat-form {
            animation-name: translateX;
        }
        #{$self}__fullsize, #{$self}__no-clients {
            animation-name: opacity;
        }
    }
    &-leaving {
        #{$self}__panel-wrap, #{$self}__no-clients,
        #{$self}__chat-wrap, #{$self}__chat-form, #{$self}__fullsize {
            animation: translateYLeave .5s 1 both;
        }
        #{$self}__panel-wrap {
            animation-timing-function: var(--animation-elastic-1);
        }
        #{$self}__chat-form {
            animation-name: translateXLeave;
        }
        #{$self}__fullsize, #{$self}__no-clients {
            animation-name: opacityLeave;
        }
    }
}
</style>
