import {post} from "../helpers/api";
import {notificationsLogin, notificationsPassword} from "../config/otherVars";
import {EventEmitter} from "./ChatController";
import { io } from 'socket.io-client';

export default class NotificationsSocket extends EventEmitter {
    constructor(options) {
        super(['joinRoom', 'leaveRoom', 'identification', 'connect', 'disconnect', 'error']);
        this.getToken().then(token => {
            const socket = io(location.origin, {
                transports: ['websocket'],
                query: {
                    token: token,
                },
                path: '/notification-ws'
            });
            this.socket = socket;

            socket.connect();
            socket.on('error', (e) => {
                console.log('error', e)
                this.emit('error', e);
            });
            socket.on('connect', (res) => {
                socket.emit('joinRoom',  {
                    operator: options.operator
                });
                this.emit('connect', res);
            });
            socket.on('disconnect', (res) => {
                this.emit('disconnect', res);
            });
            socket.on('joinRoom', (res) => {
                this.emit('joinRoom', res);
            });
            socket.on('leaveRoom', (res) => {
                this.emit('leaveRoom', res);
            });
            socket.on('identification', (res) => {
                this.emit('identification', res);
            });
        })
    }
    getToken() {
        if(this._token) {
            return new Promise(resolve => {
                resolve(this._token)
            });
        } else {
            return post(location.origin + `/notification-api/api/users/login`, {
                login: notificationsLogin,
                password: notificationsPassword
            })
                .then(({data}) => {
                    this._token = data.access_token;
                    return data.access_token;
                })
        }
    }
    destroy() {
        const socket = this.socket;
        socket.disconnect();
        socket.off();
    }
}
