<template>
    <div
        class="chat-client-panel"
        :class="{
            'chat-client-panel--theme-success': userData.bank_client_id,
            'chat-client-panel--theme-warning':
                userData.id && !userData.bank_client_id,
        }"
    >
        <div class="chat-client-panel__avatar">
            <div class="chat-client-panel__avatar-icon">
                <AppIcon icon="user" />
            </div>
            <div class="chat-client-panel__avatar-name">
                <BankUserTags
                    :value="userData.username"
                    :user-data="userData"
                />
            </div>
        </div>
        <div
            v-for="item in clientData"
            :key="item.icon"
            class="chat-client-panel__item hello-name hello-name--size-medium"
            :class="'hello-name--theme-' + item.theme"
        >
            <ProblemDescriptionItem
                :icon="item.icon"
                :name="item.name"
                :value="item.value"
            />
        </div>
        <AppButton
            v-if="isEdit"
            class="chat-client-panel__button"
            theme="gray"
            round
            size="medium-extra"
            @click="$emit('edit')"
        >
            <AppIcon icon="qalam" />
        </AppButton>
    </div>
</template>
<script>
import { ChatRoom } from "../../../../services/chat/ChatModel";
import AppIcon from "../../AppIcon";
import BankUserTags from "../../../BankUserTags";
import AppButton from "../../AppButton";
import { getUserDataFromCallData } from "../../../../helpers/chats";
import ProblemDescriptionItem from "@/components/partials/ProblemDescriptionItem";

export default {
    name: "ChatClientPanel",
    components: { AppButton, BankUserTags, AppIcon, ProblemDescriptionItem },
    props: {
        chatData: {
            type: ChatRoom,
            required: true,
        },
        isEdit: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        userData() {
            return getUserDataFromCallData(this.chatData);
        },
        clientData() {
            return [
                {
                    icon: "calendar-event",
                    name: "common.dateOfBirthday",
                    value: this.userData.birthday,
                },
                {
                    icon: "code",
                    name: "components.common.clientCode",
                    value: this.userData.code,
                },
                {
                    icon: "id-card",
                    name: "common.passportNumber",
                    value: this.userData.passport_no,
                },
                {
                    icon: "phone-stroke",
                    name: "components.common.phoneNumber",
                    value:
                        this.chatData.selectedPhone || this.chatData.data.phone,
                },
                {
                    icon: this.chatData.requestResolved
                        ? "user-check"
                        : "user-cancel",
                    name: "common.status",
                    value:
                        (this.chatData.requestResolved ? "И" : "Не и") +
                        "дентифицирован",
                    theme: this.chatData.requestResolved ? "" : "danger",
                },
            ];
        },
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.chat-client-panel {
    $self: &;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 12px;
    padding: 17px 34px 15px;
    position: relative;
    &__avatar {
        margin-right: auto;
        display: flex;
        align-items: center;
    }
    &__avatar-icon {
        margin-right: 8px;
        width: 44px;
        height: 44px;
        background-color: var(
            --chat-client-avatar-bg,
            var(--color-white-bluesh)
        );
        color: var(--chat-client-avatar-cl, var(--color-primary));
        padding: 9px;
        border-radius: 100%;
        object-fit: cover;
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    &__avatar-name {
        width: 440px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        max-height: 40px;
        overflow: hidden;
        text-transform: uppercase;
        white-space: nowrap;
    }
    &__item {
        margin-left: 42px;
    }
    &__button {
        margin-left: 34px;
        padding: 10px;
        color: #949494;
    }
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 34px;
        right: 34px;
        height: 2px;
        border-radius: 12px;
        background-color: var(--chat-client-indicator-cl, transparent);
    }
    &--theme-warning {
        --chat-client-indicator-cl: var(--color-warning);
        --chat-client-avatar-bg: var(--color-white-yellowish);
        --chat-client-avatar-cl: var(--color-warning);
    }
    &--theme-success {
        --chat-client-indicator-cl: var(--color-success);
        --chat-client-avatar-bg: var(--color-white-greenish);
        --chat-client-avatar-cl: var(--color-success);
    }
}

.chat-client-panel-on-modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;

    .chat-client-panel {
        border-radius: 0;
    }
}

.chat-client-panel-on-modal-enter-active {
    transition: all 0.6s;
}

.chat-client-panel-on-modal-leave-active {
    transition: all 0.6s;
}

.chat-client-panel-on-modal-enter,
.chat-client-panel-on-modal-leave-to {
    transform: translateY(-100%);
}
</style>
