// Мелкое управление чатом

import {getNextLang} from "../LangController";
import {getCustomerByPhone, useUpdateChat} from "../../helpers/customerHelpers";

class ChatWindowService {
    readMessage(chatData) {
        const [data, update] = useUpdateChat(chatData);
        data.unreadMessages = Math.max(chatData.unreadMessages - 1, 0);
        update();
    }
    nextLang(chatData) {
        const [data, update] = useUpdateChat(chatData);
        data.lang = getNextLang(chatData.lang);
        update();
    }
    saveScroll(scrollTop, chatData) {
        const [data, update] = useUpdateChat(chatData);
        data.savedScroll = scrollTop;
        update();
    }
    getCustomerByPhone(phone, chatData) {
        getCustomerByPhone(phone, chatData)
            .then(response => {
                if(!response.data.data.length) {
                    const [dataToUpdate, update] = useUpdateChat(chatData);
                    dataToUpdate.tempPhone = phone;
                    dataToUpdate.searchFailed = true;
                    dataToUpdate.selectedCustomer = null;
                    dataToUpdate.isNewClient = false;
                    update();
                }
            });
    }
}

export default new ChatWindowService();
