<template>
    <div class="chat-window__panel chat-window__panel--error">
        <AppIcon icon="alert-circle"/>
        {{ $t("components.common.TheClientHasLeftTheChatYouCanSafelyEndTheChat") }}
        <div class="chat-window__panel-tip">{{ $t("components.common.clickHere") }} 👆</div>
    </div>
</template>
<script>
import AppIcon from "../../AppIcon";
export default {
    name: 'ClientLeave',
    components: {AppIcon}
}
</script>
