<template>
    <div class="chat-incoming-empty">
        <div class="chat-incoming-empty__text chat-hello chat-hello--empty">
            <div class="chat-hello__image">
                <img src="@/assets/img/chat/hello-chat-002.gif" alt="">
            </div>
            <div class="chat-hello__name">{{ $t("components.common.thereIsNoOneYetWeWillTalkSoon") }}🤞</div>
        </div>
        <AppButton class="chat-incoming-empty__button chat-button" theme="gray">
            {{ $t("components.common.pending") }}
        </AppButton>
    </div>
</template>
<script>
import AppButton from "@/components/partials/AppButton";
export default {
    name: 'ChatIncomingEmpty',
    components: {AppButton},
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.chat-incoming-empty {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 34px 18px 30px;
    background-color: #fff;
    border-radius: 12px;
    &__text {
        margin-right: 16px;
    }
    &__button {
        flex-shrink: 0;
    }
}
</style>
