import ChatController, {ACCEPTED_CHAT, CLEAR_INCOMING_CHAT, INCOMING_CHAT, LOST_REQUEST} from "../ChatController";
import app from '../../main';
import {EventBus} from "../../helpers/eventBus";

// Синглтон для управления очередью входящих чатов
class IncomingChatService {
    constructor() {
        this.onIncomingChat = this.onIncomingChat.bind(this);
        this.onClearIncomingChat = this.onClearIncomingChat.bind(this);
        this.onAcceptedChat = this.onAcceptedChat.bind(this);
    }
    get incomingChat() {
        return app.$store.state.chat.incomingChat;
    }
    get activeChat() {
        return app.$store.state.chat.activeChatId;
    }
    listen() {
        ChatController.on(INCOMING_CHAT, this.onIncomingChat);
        ChatController.on(CLEAR_INCOMING_CHAT, this.onClearIncomingChat);
        ChatController.on(LOST_REQUEST, this.onLostRequest);

        const incoming = ChatController.getIncomingChatFromQueue();
        if(incoming) {
            this.onIncomingChat(incoming);
        }
    }
    destroy() {
        ChatController.off(INCOMING_CHAT, this.onIncomingChat);
        ChatController.off(CLEAR_INCOMING_CHAT, this.onClearIncomingChat);
        ChatController.off(LOST_REQUEST, this.onLostRequest);
    }
    onIncomingChat(incomingChat) {
        if(this.incomingChat) return;

        app.$store.commit('chat/updateIncomingChat', incomingChat);
    }
    onClearIncomingChat() {
        app.$store.commit('chat/updateIncomingChat', null);
    }
    onAcceptedChat() {
        setTimeout(() => {
            const chat = ChatController.getIncomingChatFromQueue();
            if(chat) {
                this.onIncomingChat(chat);
            }
        });
    }
    onLostRequest() {
        EventBus.$emit('system-agent-lost-call', {isChat: true});
    }
    acceptChat() {
        if(this.incomingChat) {
            ChatController.acceptChat(this.incomingChat);
            app.$store.commit("setOperatorPickedUpPhone", true)
            this.onClearIncomingChat();
        }
    }
}
export default new IncomingChatService;
