import {get, post} from "../../helpers/api";
import app from "../../main";
import ChatController from "../ChatController";
import {addCustomer, pingUpdatedFields, selectCustomer, useUpdateChat} from "../../helpers/customerHelpers";
import NotificationsSocket from "../NotificationsSocket";

const notificationsSocket = new NotificationsSocket({operator: app.$store.state.auth.username});

class IdentificationChatService {
    sendIdentification(e, chatData) {
        const now = Date.now();
        if((chatData.requestResolved || chatData.requestResendAt > now) && !e.forced) return;

        const tempPhone = e.phone;

        let phone = chatData.selectedPhone || tempPhone || chatData.tempPhone || chatData.data.phone;

        if(phone.length.toString() <= 9) {
            phone = process.env.VUE_APP_CISCO_PHONE_PREFIX + phone;
        }

        if(!phone) {
            app.$store.commit("notifications/error", "Пожалуйста, укажите телефон в карточке клиента");
            return;
        }

        const [dataToUpdate, updateData] = useUpdateChat(chatData);

        if(e.forced) {
            dataToUpdate.requestId = null;
            dataToUpdate.requestResolved = false;
        }

        dataToUpdate.requestSent = true;
        dataToUpdate.requestResendAt = now + 65000;
        dataToUpdate.requestLoading = true;

        if(!chatData.selectedCustomer) {
            dataToUpdate.selectedPhone = phone;
        }

        updateData();

        post("/identification-request", {
            source_id: chatData.data.sourceId,
            phone
        })
            .then(({data}) => {
                if(data.success) {
                    const [dataToUpdate, updateData] = useUpdateChat(chatData);
                    let message = "Сўровингизга асосан актив амалиётларни бажариш учун MyID тизими орқали идентификациядан ўтишингизни сўраймиз.\n\n" + data.identification_url;
                    if(chatData.lang === "ru") {
                        message = "Для того чтобы выполнять активные операции, вам нужно идентифицироваться в системе MyID.\n\nПерейдите по ссылке:\n" + data.identification_url;
                    }
                    ChatController.sendMessage({
                        message,
                        chatRoom: chatData.chatRoom
                    });

                    dataToUpdate.identificationUrls = data.identification_url;
                    dataToUpdate.requestId = data.request_id;
                    updateData();
                } else {
                    throw new Error();
                }
            })
            .catch(e => {
                app.$store.commit("notifications/error", "Не удалось идентифицировать пользователя");
            })
            .finally(() => {
                const [dataToUpdate, updateData] = useUpdateChat(chatData);
                dataToUpdate.requestLoading = false;
                updateData();
            });
    }
    connectNotificationsSocket() {
        notificationsSocket.on("identification", this.processIdentificationMessage);
    }
    disconnectNotificationsSocket() {
        notificationsSocket.off("identification", this.processIdentificationMessage);
    }
    processIdentificationMessage(data) {
        let chatData = Object.values(app.$store.state.chat.chatsData).find(chat => {
            return chat.requestId === data.requestId;
        });
        if(chatData) {
            const [dataToUpdate, update] = useUpdateChat(chatData);
            dataToUpdate.messages = [{
                body: data,
                createdAt: new Date()
            }];
            if(data.success) {
                dataToUpdate.requestResolved = true;
                dataToUpdate.unreadMessages = chatData.unreadMessages + 1;
                if(data.donoCustomer) {
                    get("/customers/" + data.donoCustomer).then(response => {
                        if (response.data.success) {
                            addCustomer(response.data.data, chatData);
                            selectCustomer(response.data.data.id, chatData);
                            pingUpdatedFields(Object.keys(response.data.data), chatData);
                        } else {
                            throw new Error();
                        }
                        return response;
                    })
                        .catch(e => {
                            app.$store.commit("notifications/error", "Не удалось получить информацию о клиенте");
                        });
                }
            }
            update();
        }
    }
}
export default new IdentificationChatService();
