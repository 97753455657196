<template>
    <div class="chat-user-pan" :class="[
            data ? isOnline ? 'chat-user-pan--online' : 'chat-user-pan--offline' : '',
            { 'chat-user-pan--active': active, 'chat-user-pan--horizontal': horizontal, 'chat-user-pan--empty': !data }
        ]"
         @click="$emit('click', $event)">
        <AvatarFrame class="chat-user-pan__image-wrap" size="small" #avatar state="not-ready" :disabled="horizontal || !data || isOnline" is-enter ref="avatar-frame">
            <div v-if="unreadMessages" class="chat-user-pan__messages-count message-counter message-counter--size-small">{{ unreadMessages }}</div>

            <div v-if="!data" class="chat-user-pan__icon">
                <AppIcon icon="plus"/>
            </div>
            <img v-else-if="data.avatar" class="chat-user-pan__image" :src="data.avatar" alt="">
            <NoAvatar v-else class="chat-user-pan__placeholder" :key-name="chatData.chatRoom" :user-name="title"/>
        </AvatarFrame>

        <div class="chat-user-pan__wrap">
            <div class="chat-user-pan__name">{{ title }}</div>
            <div class="chat-user-pan__subname">{{ subtitle }}</div>
        </div>
    </div>
</template>
<script>
import AppIcon from "../AppIcon";
import NoAvatar from "./NoAvatar";
import AvatarFrame from "../../layout/AvatarFrame";
import {ChatRoom} from "../../../services/chat/ChatModel";
export default {
    name: 'ChatUserPan',
    components: {AvatarFrame, NoAvatar, AppIcon},
    props: {
        chatData: ChatRoom,
        active: Boolean,
        horizontal: Boolean
    },
    watch: {
        'isOnline': {
            handler: 'startAnimation',
            immediate: true
        }
    },
    computed: {
        enabled() {
            return !!this.chatData;
        },
        isOnline() {
            return this.chatData && this.chatData.online;
        },
        data() {
            return this.chatData && this.chatData.data;
        },
        unreadMessages() {
            if(!this.data) return 0;
            return this.chatData.unreadMessages;
        },
        title() {
            return this.data ? this.data.userName : 'Свободно';
        },
        subtitle() {
            return this.data ? this.data.source : 'Пока тихо'
        },
    },
    methods: {
        startAnimation(value) {
            if (!value) {
                this.$nextTick(() => {
                    this.$refs["avatar-frame"].startAnimation();
                });
            }
        }
    }
}
</script>
<style lang="scss">
.chat-user-pan {
    $self: &;
    text-align: center;
    position: relative;
    background: var(--back);
    &__image-wrap {
        position: relative;
        margin: 0 auto 6px;
        width: 52px;
        height: 52px;
    }
    &__icon,
    &__image {
        border-radius: 100%;
        width: 100%;
        height: 100%;
    }
    &__icon {
        background: var(--color-border-lighten);
        padding: 10px;
        color: var(--color-gray-light);
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    &__image {
        display: block;
        object-fit: cover;
    }
    &__messages-count {
        z-index: 1;
        position: absolute;
        top: 4px;
        right: 0;
    }
    &__name {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--color-dark);
        max-width: 100%;
        width: 140px;
        padding-left: 14px;
        padding-right: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-left: auto;
        margin-right: auto;
    }
    &__subname {
        font-weight: 500;
        font-size: 9px;
        line-height: 11px;
        color: var(--color-gray-light);
    }
    &--active {
        #{$self}__subname {
            color: var(--color-primary);
        }
    }
    &--online,
    &--offline {
        #{$self}__image-wrap {
            &:before {
                z-index: 1;
                content: '';
                width: 14px;
                height: 14px;
                position: absolute;
                bottom: 1px;
                right: 2px;
                border: 3px solid var(--color-white);
                background: var(--color-success);
                border-radius: 100%;
            }
        }
    }
    &--offline {
        --back: var(--color-white);
        #{$self}__image-wrap {
            &:before {
                background: var(--color-danger);
                z-index: 1;
            }
        }
    }
    &--horizontal {
        padding: 7px 15px;
        border: 1px solid var(--border, #E1E1E1);
        background: var(--back);
        border-radius: 12px;
        display: flex;
        align-items: center;
        text-align: left;
        #{$self}__image-wrap {
            margin: -4px;
            flex-shrink: 0;
        }
        #{$self}__wrap {
            margin-left: 12px;
            margin-top: -4px;
            max-width: calc(100% - 56px);
        }
        #{$self}__name {
            padding: 0;
            margin: 0;
        }
        &#{$self}--empty {
            border-style: dashed;
        }
        &#{$self}--offline,
        &#{$self}--online {
            #{$self}__image-wrap {
                &:before {
                    right: 4px;
                    bottom: 4px;
                }
            }
        }
    }
    &--active {
        --back: var(--color-white-bluesh);
        --border: var(--color-primary);
    }
}
</style>
