<template>
    <div class="chat-window__header">
        <div class="chat-window__user chat-user-avatar" :class="{'chat-user-avatar--offline': !chatData.online}">
            <div class="chat-user-avatar__lang" @click="changeLang">
                <img :src="langIcon" alt="flag"/>
            </div>
            <img v-if="chatData.data.avatar" :src="chatData.data.avatar" alt="" class="chat-user-avatar__image">
            <NoAvatar v-else :user-name="chatData.data.userName" :key-name="chatData.chatRoom" class="chat-user-avatar__image" size="medium"/>
            <div class="chat-user-avatar__wrap">
                <div class="chat-user-avatar__name">
                    <BankUserTags :class="{'user-tags-shaking': usernameChanged}" :value="chatData.data.userName" :user-data="userData"/>
                </div>
                <div class="chat-user-avatar__subname">{{ chatData.online ? $t('components.common.online') : $t('components.common.offline') }}</div>
            </div>
        </div>
        <div class="chat-window__controls">
            <IdentificationPhone v-if="chatData.online" :chat-data="chatData"/>
            <ChatWindowSettings :chat-data="chatData"/>
        </div>
    </div>
</template>
<script>
import {ChatRoom} from "../../../../services/chat/ChatModel";
import NoAvatar from "../NoAvatar";
import BankUserTags from "../../../BankUserTags";
import IdentificationPhone from "./IdentificationPhone";
import ChatWindowSettings from "./ChatWindowSettings";
import AppIcon from "../../AppIcon";
import chatWindowService from "../../../../services/chat/ChatWindowService";
import {qqLang, ruLang, uzLang} from "../../../../config/lang";

export default {
    name: 'ChatWindowHeader',
    components: {AppIcon, ChatWindowSettings, IdentificationPhone, BankUserTags, NoAvatar},
    props: {
        chatData: ChatRoom
    },
    computed: {
        usernameChanged() {
            return 'username' in this.chatData.customerFieldsUpdated || 'is_abs_active' in this.chatData.customerFieldsUpdated;
        },
        langIcon() {
            switch(this.chatData.lang) {
                case 'ru': return ruLang;
                case 'uz': return uzLang;
                case 'qq': return qqLang;
            }
        },
        userData() {
            if(!this.chatData) return null;
            return this.chatData.customers.find(c => c.id === this.chatData.selectedCustomer);
        }
    },
    methods: {
        changeLang() {
            chatWindowService.nextLang(this.chatData);
        },
    }
}
</script>
