<template>
    <div class="chat-layout__chat-content">
        <div class="chat-layout__chat-wrap">
            <ChatUsersNavbar
                class="chat-layout__chat-navbar"
                :chat-data="chatData"
            />
            <ChatWindow
                class="chat-layout__chat-window"
                :chat-data="chatData"
            />
        </div>
        <AboutClient
            class="chat-layout__chat-form"
            :commands="commandsList"
            is-chat
            :call-data="chatData"
            @identification="identify"
            @open:modal="openModal"
        />

        <transition name="chat-client-panel-on-modal">
            <div class="chat-client-panel-on-modal" v-if="modalWindowFullscreen">
                <ChatClientPanel :chat-data="chatData" :is-edit="false" />
            </div>
        </transition>

        <CreditProductModal
            v-if="modals.creditProduct"
            :calculationType="calculationType"
            :product-info="productInfo"
            :product-table="calculationTable"
            :call-data="chatData"
            :slide="slide"
            @cancel:form="closeModal"
            @close-modal="closeModal"
            @submit:credit-product="submitCreditProduct($event)"
            @submit:deposit-product="submitDepositProduct($event)"
            @reset:step="slide = 'default'"
        />
    </div>
</template>
<script>
import ChatUsersNavbar from "./controls/ChatUsersNavbar";
import ChatWindow from "./ChatWindow";
import AboutClient from "../../aboutClient";
import { CustomerCommands } from "../../../services/chat/CustomerController";
import { ChatRoom } from "../../../services/chat/ChatModel";
import identificationChatService from "../../../services/chat/IdentificationChatService";
import { mapState } from "vuex";
import CreditProductModal from "@/components/modals/CreditProductModal.vue";
import { get, post } from "@/helpers/api";
import FullScreenModalMixin from "@/mixins/FullScreenModalMixin.js";
import ChatClientPanel from "./controls/ChatClientPanel.vue";
import {EventBus} from "@/helpers/eventBus";
export default {
    name: "ChatForm",
    mixins: [FullScreenModalMixin],
    components: {
        CreditProductModal,
        AboutClient,
        ChatWindow,
        ChatUsersNavbar,
        ChatClientPanel,
    },
    props: {
        chatData: ChatRoom,
    },
    data() {
        return {
            modals: {
                creditProduct: false,
            },
            processingCards: {},
            productInfo: {},
            calculationTable: {},
            clientFormInfo: {},
            calculationType: "",
            slide: "default",
        };
    },
    computed: {
        ...mapState("chat", ["chatsData"]),
        commandsList() {
            return new CustomerCommands(this.chatData) || {};
        },
    },
    watch: {
        chatsData(value) {
            if (!(this.chatData.chatRoom in value)) {
                this.$destroy();
            }
        },
    },
    methods: {
        identify() {
            identificationChatService.sendIdentification(
                { forced: true, phone: this.chatData.tempPhone },
                this.chatData
            );
        },
        openModal(id) {
            get(`/products/${id}`)
                .then((res) => {
                    if (res.data.success) {
                        this.productInfo = res.data.data;

                        this.modals.creditProduct = true;
                    }
                })
                .catch(() => {
                    this.$store.commit(
                        "notifications/error",
                        "errors.somethingWentWrong"
                    );
                });
        },
        closeModal() {
            this.slide = "default";
            this.modals.creditProduct = false;
        },
        submitCreditProduct(form) {
            post("/calculator/credit", form)
                .then((res) => {
                    this.calculationType = "credit";
                    this.calculationTable = res.data.data;
                    this.slide = "results";
                })
                .catch(() => {
                    this.$store.commit(
                        "notifications/error",
                        "errors.somethingWentWrong"
                    );
                });
        },
        submitDepositProduct(form) {
            post("/calculator/deposit", form)
                .then((res) => {
                    this.calculationType = "deposit";
                    this.calculationTable = res.data.data;
                    this.slide = "results";
                })
                .catch(() => {
                    this.$store.commit(
                        "notifications/error",
                        "errors.somethingWentWrong"
                    );
                });
        },
    }
};
</script>

<style lang="scss">

</style>
