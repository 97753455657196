<template>
    <div v-if="chatData.online" class="chat-window__control">
        <LoadingContent v-if="identificationPhone" :loading="identificationLoading">
            <AppButton :theme="identificationButtonTheme" size="medium-extra" v-tooltip="identificationButtonText" round
                       @click="sendUserIdentificationNoPhone">
                <AppIcon icon="user-check"/>
            </AppButton>
        </LoadingContent>
        <PhoneIdentification v-else :button-theme="identificationButtonTheme" :loading="identificationLoading"
                             button-icon="user-check" :button-tooltip-text="identificationButtonText" id="i"
                             @submit="sendUserIdentification"/>
    </div>
</template>
<script>
import LoadingContent from "../../LoadingContent";
import AppButton from "../../AppButton";
import AppIcon from "../../AppIcon";
import PhoneIdentification from "../../pages/PhoneIdentification";
import {ChatRoom} from "../../../../services/chat/ChatModel";
import identificationChatService from "../../../../services/chat/IdentificationChatService";
export default {
    name: 'IdentificationPhone',
    components: {PhoneIdentification, AppIcon, AppButton, LoadingContent},
    props: {
        chatData: {
            type: ChatRoom,
            required: true
        }
    },
    computed: {
        identificationPhone() {
            return this.chatData.selectedPhone || this.chatData.tempPhone || this.chatData.data.incomingPhone
        },
        identificationLoading() {
            return this.chatData.requestLoading;
        },
        identificationButtonText() {
            if(this.chatData.requestResolved) {
                return 'Идентифицирован'
            } else {
                return 'Не идентифицирован';
            }
        },
        identificationButtonTheme() {
            if(this.chatData.requestResolved) {
                return 'success-outline';
            } else if(this.chatData.requestSent) {
                return 'primary-dark-outline'
            } else {
                return 'primary-dark';
            }
        },
    },
    methods: {
        sendUserIdentificationNoPhone() {
            identificationChatService.sendIdentification({forced: false}, this.chatData);
        },
        sendUserIdentification(phone) {
            identificationChatService.sendIdentification({forced: false, phone}, this.chatData);
        },
    }
}
</script>
